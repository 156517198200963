<template>
  <v-container>
    <v-form @submit.prevent="">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="options.params.name"
            label="Name"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="defaultFacilityFilter"
            :items="facilitiesStore.allFacilities"
            item-text="title"
            item-value="id"
            label="Default Facility"
            clearable
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      :headers="headers"
      :items="results"
      :loading="loading"
      :options="options"
    >
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.facility_id="{ item }">
        {{ getUserFacility(item) }}
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.active="{ item }">
        <v-icon :color="item.active ? 'success' : 'error'">{{
          item.active ? "mdi-check" : "mdi-cancel"
        }}</v-icon>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col cols="12">
            <ButtonAction
              label="Settings"
              icon="mdi-cog"
              @click="navigateToSettings(item.id)"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { ref, computed, set } from "vue-demi";
import { useFind } from "feathers-pinia";
import ButtonAction from "./ButtonAction.vue";
import { useFacilities } from "@/store/facilities.pinia.js";
import { useMembers } from "@/store/members.pinia";
import { useUserSettings } from "../store/userSettings.pinia";

export default {
  name: "AdminUsers",
  components: {
    ButtonAction,
  },
  setup(props, { root }) {
    const membersStore = useMembers();
    const userSettingsStore = useUserSettings();
    const quickViewItem = ref(null);
    const facilities = ref([]);
    // Get quick view facility name
    const quickViewItemFacility = computed(() => {
      return facilities?.value?.find(
        (facility) => facility.id === quickViewItem.value?.facility_id
      );
    });
    const facilitiesStore = useFacilities();
    // Query for all facilities
    facilitiesStore.find({ query: {} }).then((res) => {
      facilities.value = res.data;
    });

    const options = ref({
      itemsPerPage: 15,
      params: {
        name: "",
      },
    });

    const headers = ref([
      { text: "Display Name", value: "displayName" },
      { text: "Default Facility", value: "defaultFacility.name" },
      { text: "Actions", value: "actions", sortable: false },
    ]);

    const activeFilterOptions = ref([
      { title: "All", value: null },
      { title: "Active", value: 1 },
      { title: "Inactive", value: 0 },
    ]);

    const defaultFacilityFilter = ref(null);
    const loading = ref(true);
    const items = ref([]);
    // Load the users
    membersStore.getGroupMembers().then((res) => {
      loading.value = false;
      // items.value = res.data;
      items.value = res?.data ?? [];
    });

    const userSettingsParams = computed(() => {
      const query = {
        $limit: 1000,
        property: "default_facility_id",
      };
      return { query };
    });

    const { items: userSettings } = useFind({
      model: userSettingsStore.Model,
      params: userSettingsParams,
    });

    const getUserFacility = (user) => {
      if (!Array.isArray(userSettings.value)) return {};
      const userSetting = userSettings.value?.find(
        (setting) => setting.user_id === user.id
      );
      if (!userSetting?.value) return {};
      return {
        name: facilitiesStore.getFacilityName(userSetting?.value),
        id: userSetting?.value,
      };
    };

    const results = computed(() => {
      return (items.value?.length ? items.value : [])
        .map((item) => {
          return {
            ...item,
            defaultFacility: getUserFacility(item),
          };
        })
        .filter((item) => {
          // Name filtering
          if (options.value.params.name?.length) {
            // Check both display and given name fields
            const displayName = item.displayName?.toLowerCase() ?? "";
            const givenName = item.givenName?.toLowerCase() ?? "";
            if (
              !displayName.includes(options.value.params.name.toLowerCase()) &&
              !givenName.includes(options.value.params.name.toLowerCase())
            ) {
              return false;
            }
          }
          if (
            typeof defaultFacilityFilter.value === "string" &&
            defaultFacilityFilter.value.length &&
            item.defaultFacility?.id !== defaultFacilityFilter.value
          ) {
            return false;
          }
          // Default to true
          return true;
        });
    });

    function navigateToPermissions(userId) {
      root.$router.push({
        name: "AdminUsersPermissions",
        params: { userId },
      });
    }

    function navigateToSettings(id) {
      root.$router.push({ name: "AdminUsersSettings", params: { userId: id } });
    }

    return {
      options,
      headers,
      activeFilterOptions,
      defaultFacilityFilter,
      loading,
      results,
      userSettings,
      facilitiesStore,
      quickViewItemFacility,
      quickViewItem,
      facilities,
      navigateToSettings,
      navigateToPermissions,
      getUserFacility,
    };
  },
  watch: {
    defaultFacilityFilter(newVal) {
      // If no value is selected, reset the param
      if (!newVal) {
        set(this.options.params, "id", null);
        return;
      }
      // Get all user IDs with newVal as the default_facility_id
      const userIds = this.userSettings
        .filter((setting) => setting.value === newVal)
        .map((setting) => setting.user_id);
      // Filter users by user IDs
      set(this.options.params, "id", { $in: userIds });
    },
  },
};
</script>

<style scoped></style>
