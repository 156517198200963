<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        icon
        x-small
        @click="handleClick"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "ButtonAction",
  props: {
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    color: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>
